import { Component, OnInit } from '@angular/core';
import { LogviewerService } from 'src/app/shared/services/logviewer.service';
import { map, skip, take, reduce } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';

@Component({
  selector: 'app-logviewer-latency',
  templateUrl: './logviewer-latency.component.html',
  styleUrls: ['./logviewer-latency.component.scss']
})
export class LogviewerLatencyComponent implements OnInit {
  latencies: any[][];

  constructor(private logviewerService: LogviewerService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.logviewerService.latency(this.route.snapshot.params.date)
      .pipe(map(res => res.filter(r => r.length > 0)))
      .subscribe(
        data => {
          this.latencies = data;
        });
  }

  total(item: any[], index: number) {
    return from(item).pipe(skip(1))
      .pipe(take(index))
      .pipe(map(o => o.duration))
      .pipe(reduce((acc, val) => acc + +val, 0)); // +val is intentional to force string to integer
  }

}
