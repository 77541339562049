import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LogviewerIndexComponent } from './logviewer/index/logviewer-index.component';
import { LogviewerLatencyComponent } from './logviewer/latency/logviewer-latency.component';
import { LogviewerViewdbComponent } from './logviewer/viewdb/logviewer-viewdb.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  { path: 'login', component: LoginComponent },
  {
    path: 'logviewer', canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    children: [
      { path: 'latency', component: LogviewerLatencyComponent },
      { path: 'latency/:date', component: LogviewerLatencyComponent },
      { path: 'viewdb', component: LogviewerViewdbComponent },
      { path: ':page', component: LogviewerIndexComponent },
      { path: ':page/:num', component: LogviewerIndexComponent },
      { path: ':page/:num/:sort', component: LogviewerIndexComponent },
      { path: 'logfile/:file/:num/:sort', component: LogviewerIndexComponent },
      { path: '', redirectTo: 'index', pathMatch: 'full' }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
