import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../helpers/config';

@Injectable({
  providedIn: 'root'
})
export class LogviewerService {

  constructor(private http: HttpClient, private config: Config) { }

  index(params) {
    return this.http.get<string[]>(`${this.config.apiUrl}/logviewer/${params.page}/${params.num || 100}/${params.sort || 'desc'}`);
  }

  logfile(params) {
    return this.http.get<string[]>(`${this.config.apiUrl}/logviewer/LogFile/${params.file}/${params.num || 100}/${params.sort || 'desc'}`);
  }

  clearlogfile(file) {
    return this.http.get<string[]>(`${this.config.apiUrl}/logviewer/ClearLogFile/${file}`);
  }

  latency(date) {
    if(date)
      return this.http.get<any[][]>(`${this.config.apiUrl}/logviewer/latency/${date}`);
    else
      return this.http.get<any[][]>(`${this.config.apiUrl}/logviewer/latency`);
  }

  viewdb() {
    return this.http.get<any>(`${this.config.apiUrl}/logviewer/viewdb`);
  }

  resetdb() {
    return this.http.get<any>(`${this.config.apiUrl}/logviewer/resetdb`);
  }
}
