import { Component, OnInit } from '@angular/core';
import { LogviewerService } from 'src/app/shared/services/logviewer.service';

@Component({
  selector: 'app-logviewer-viewdb',
  templateUrl: './logviewer-viewdb.component.html',
  styleUrls: ['./logviewer-viewdb.component.scss']
})
export class LogviewerViewdbComponent implements OnInit {
  dbdata: any = {};

  constructor(private logviewerService: LogviewerService) { }

  ngOnInit() {
    this.logviewerService.viewdb().subscribe(data => { this.dbdata = data; })
  }

  resetdb() {
    this.logviewerService.resetdb().subscribe(data => { this.dbdata = data; })
  }

}
