import { Component, OnInit } from '@angular/core';
import { LogviewerService } from '../../shared/services/logviewer.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-logviewer-index',
  templateUrl: './logviewer-index.component.html',
  styleUrls: ['./logviewer-index.component.scss']
})
export class LogviewerIndexComponent implements OnInit {
  loading = false;
  logs: string[] = [];
  file: string;

  constructor(private logviewerService: LogviewerService, private route: ActivatedRoute) { }

  ngOnInit() {
    var params = this.route.snapshot.params;
    this.file = params.file;
    var service = null;

    if (params.file) {
      service = this.logviewerService.logfile(params);
    }
    else
      service = this.logviewerService.index(params);

    this.handleService(service);
  }

  handleService(service) {
    service.pipe(first()).subscribe(
      data => {
        this.logs = data;
      });
  }

  clearLogs() {
    this.handleService(this.logviewerService.clearlogfile(this.file));
  }

}
